export const exportTemplates = [
    {
        key: 'payment',
        templates: [
            {
                name: 'Default',
                value: 'default',
            },
            {
                name: 'Line Items',
                value: 'line-items',
            },
            // {
            //     name: "Quaderno Invoice Import",
            //     value: "quaderno-invoice-import"
            // }
        ],
    },
    {
        key: 'download',
        templates: [
            {
                name: 'Default',
                value: 'default',
            },
        ],
    },
];

export const eddDataTypes = [
    {
        name: 'Payments',
        value: 'payment',
        fields: [
            {
                name: 'UTC Date',
                value: 'date',
            },
            {
                name: 'Store Date',
                value: 'store_date',
            },
            {
                name: 'Status',
                value: 'status',
            },
            {
                name: 'Type',
                value: 'type',
            },
            {
                name: 'Subtotal',
                value: 'subtotal',
            },
            {
                name: 'Tax Amount',
                value: 'tax',
            },
            {
                name: 'Discount Amount',
                value: 'discount',
            },
            {
                name: 'Total',
                value: 'total',
            },
            {
                name: 'Refund Amount',
                value: 'refund_amount',
            },
            {
                name: 'Adjusted Total',
                value: 'adjusted_total',
            },
            {
                name: 'Currency',
                value: 'currency',
            },
            {
                name: 'Payment Gateway',
                value: 'gateway',
            },
            {
                name: 'Order Email',
                value: 'email',
            },
            {
                name: 'Device IP',
                value: 'ip',
            },
            {
                name: 'Cart Items',
                value: 'downloads',
            },
            {
                name: 'Customer ID',
                value: 'customer_id',
            },
            {
                name: 'WP User ID',
                value: 'user_id',
            },
            {
                name: 'Billing Name',
                value: 'billing_name',
            },
            {
                name: 'Billing Address',
                value: 'billing_address',
            },
            {
                name: 'Billing City',
                value: 'billing_city',
            },
            {
                name: 'Billing Region',
                value: 'billing_region',
            },
            {
                name: 'Billing Country',
                value: 'billing_country',
            },
            {
                name: 'Billing Postal Code',
                value: 'billing_postal_code',
            },
            {
                name: 'Shipping Recipient',
                value: 'shipping_recipient',
            },
            {
                name: 'Shipping Phone',
                value: 'shipping_phone',
            },
            {
                name: 'Shipping Address',
                value: 'shipping_address',
            },
            {
                name: 'Shipping City',
                value: 'shipping_city',
            },
            {
                name: 'Shipping Region',
                value: 'shipping_region',
            },
            {
                name: 'Shipping Country',
                value: 'shipping_country',
            },
            {
                name: 'Shipping Postal Code',
                value: 'shipping_postal_code',
            },
        ],
    },

    {
        name: 'Downloads',
        value: 'download',
        fields: [
            {
                name: 'Status',
                value: 'status',
            },
            {
                name: 'Publish Date',
                value: 'date',
            },
            {
                name: 'SKU',
                value: 'sku',
            },
            {
                name: 'Categories',
                value: 'categories',
            },
        ],
    },
];
